import React from 'react';

import { MEDIA_TYPES_IDS } from 'constants/index';

import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import VideoPlayer from 'components/uiLibrary/VideoPlayer';
import Carousel from 'components/uiLibrary/Carousel';
import GallerySpaceFiller from 'components/Globals/Media/GallerySpaceFiller';

import { getMediaSource } from 'utils/media';

import classes from './MediaCarousel.module.scss';

const MediaCarousel = ({ data, title }) => {
  if (data?.length > 0) {
    return (
      <Carousel options={{ maxVisibleItems: 1, hideDisabledArrow: data.length === 1 }} styles={{ root: classes.root }}>
        {data.map(media => {
          if (media?.mediaType?.id === MEDIA_TYPES_IDS.VIDEO) {
            return <VideoPlayer media={media} width={305} height={194} inline />;
          }

          return (
            <Image
              src={getMediaSource(media)}
              width={305}
              height={194}
              transformations={TRANSFORMATIONS.SLUG_MEDIA}
              blurHash={media?.blurHash}
              alt={media?.title}
              title={media?.title}
              attribution={{
                source_info: media?.source_info,
                credit: media?.credit,
                copyright: media?.copyright,
              }}
              disableNextImage
            />
          );
        })}
      </Carousel>
    );
  }

  return <GallerySpaceFiller text={title} />;
};

export default MediaCarousel;
